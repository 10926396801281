@use "src/common/styles/" as *;

.Home {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px;
}

.titleContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  width: 100%;
  max-width: 800px;
  text-align: center;
  margin: 0 auto;
}

.title {
  font-family: $titleFontFamily;
  font-weight: 350;
  font-size: 60px;
  margin-top: 25vh;
  margin-bottom: 2vh;
  text-align: left;
  align-self: flex-start;
  line-height: 1.2;
  max-width: 100%;
}

.subtitle {
  font-family: $subtitleFontFamily;
  font-weight: $fontThin;
  font-size: 15px;
  text-align: left;
  margin-bottom: 4vh;
  max-width: 100%;
  line-height: 1.5;
}

@media (min-width: 875px) {
  .Home {
    width: 80%;
    max-width: 1000px;
    margin: 0 auto;
  }

  .titleContent {
    height: 60vh;
    width: 100%;
    max-width: 1000px;
  }

  .title {
    font-size: 75px;
  }

  .subtitle {
    font-size: 20px;
    margin-bottom: 6vh;
  }
}
