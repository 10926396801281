@use "src/common/styles/" as *;

.WritingPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px;
}

.backButton {
  display: flex;
  align-self: flex-start;
  font-size: 15px;
  color: $subtextColor;
  cursor: pointer;
  margin-bottom: 25px;

  &:hover {
    text-decoration: underline;
  }
}

.blogContentWrapper {
  width: 100%;
  padding: 10px;
}


.blogContent {
  font-family: $regularFontFamily;
  line-height: 1.8;
  // color: #333;

  h1, h2, h3 {
    // font-family: 'IBM Plex Sans Condensed', sans-serif;
    font-family: $titleFontFamily;
    font-weight: $fontSemiBold;
    color: $textColor;
  }

  h1 {
    font-size: 2.5rem;
    margin-top: 0.5rem;
    line-height: 1;
  }

  h2 {
    font-size: 2.5rem;
    margin-top: 20px;
  }

  h6 {
    font-size: 15px;
    color: $subtextColor; /* Ensure this variable is defined */
    font-family: $subtitleFontFamily; /* Ensure this variable is defined */
    // margin-bottom: 2.0rem; /* Adjust spacing between date and title */
    font-weight: $fontRegular;
    margin-bottom: 0;
  }

  p {
    font-size: 1.0rem;
    margin-bottom: 25px;
    color: $paragraphColor;
  }

  a {
    color: #2266a6;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  blockquote {
    font-style: italic;
    margin: 20px 0;
    padding-left: 15px;
    border-left: 5px solid #2266a6;
  }

  img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin: 20px 0;
  }
}

@media (min-width: 875px) {
  .WritingPage {
    width: 80%;
    max-width: 1000px;
    margin: 0 auto;
  }

  .blogContent {
    p {
      font-size: 1.2rem;
    }
  }
}