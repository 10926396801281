@use "src/common/styles/" as *;

.Writings {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px;
}

.titleContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align to the top */
  justify-content: flex-start; /* Ensure content is at the top */
  width: 100%;
  max-width: 800px;
  text-align: left;
  margin: 0 auto;
  padding-top: 20px; /* Add some padding at the top */
}

.title {
  font-family: $titleFontFamily;
  font-weight: 350;
  font-size: 60px;
  margin-bottom: 3vh;
  text-align: left;
  align-self: flex-start;
  line-height: 1.2;
  max-width: 100%;
}

.subtitle {
  font-family: $subtitleFontFamily;
  font-weight: $fontLessThin;
  font-size: 15px;
  text-align: left;
  margin-bottom: 3vh;
  max-width: 100%;
  line-height: 1.5;
}

/* Grid Styling */
.grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* 1 column for smaller screens */
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  // margin-top: 20px; /* Space above the grid */
}

.gridItem {
  padding: 20px;
  border: 1px solid $subBackgroundColor; /* Subtle border */
  border-radius: 8px;
  background-color: $cardColor; /* Card background */
  transition: transform 0.3s ease; /* Animation for hover */
  cursor: pointer;

  &:hover {
    transform: scale(1.05); /* Slight scaling effect on hover */
    background-color: $subBackgroundColor; /* Background change on hover */
  }
}

.gridItem h3 {
  font-size: 24px;
  font-weight: $fontSemiBold;
  margin-bottom: 10px;
  color: $textColor;
}

.gridItem p {
  font-size: 16px;
  font-weight: $fontRegular;
  color: $subtextColor;
}

/* Full Article Content Styling */
.articleContent {
  margin-top: 40px;
  padding: 20px;
  border-top: 1px solid $borderColor;
}

/* Responsive Adjustments */
@media (min-width: 600px) {
  .grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on medium screens */
  }
}

@media (min-width: 875px) {
  .Writings {
    width: 80%;
    max-width: 1000px;
    margin: 0 auto;
  }

  .titleContent {
    width: 100%;
    max-width: 1000px;
  }

  .title {
    font-size: 60px;
  }

  .subtitle {
    font-size: 18px;
    margin-bottom: 6vh;
  }

  .grid {
    grid-template-columns: repeat(3, 1fr); /* 3 columns on large screens */
  }
}