@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=IBM+Plex+Sans+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

// Define font properties
$titleFontFamily: "IBM Plex Sans Condensed", monospace;
$subtitleFontFamily: "IBM Plex Mono", monospace;
$regularFontFamily: "Montserrat", sans-serif;

$fontThin: 275;
$fontLessThin: 330;
$fontRegular: 400;
$fontSemiBold: 500;
$fontBold: 600;
