

.Router {
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 100px);
}