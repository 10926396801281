// // Color Palette

$backgroundColor: #fbf9f3; // Background for the site
$cardColor: #fff; // Card background or container background
$textColor: #000; // Primary text color
$buttonColor: #2266a6; // Button background color
$subtextColor: #777; // Subtext color for less prominent text
$subBackgroundColor: #d9d9d9; // Background for sub-sections or elements
$buttonTextColor: #fff; // Text color for buttons
$errorColor: #a62222; // Error or warning colors (e.g., for error messages)
$linkHoverColor: #1e90ff; // Brighter color for link hover states
$borderColor: #4a4a4a; // Border color for dividers, etc.
$paragraphColor: #555555; // Color for paragraphs

$black: #000000;
$white: #ffffff;
$gray: #f8f9fa;
