@use "src/common/styles/" as *;

.Header {
  display: flex;
  justify-content: space-between; /* Space between logo and header items */
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid $borderColor; /* Using defined border color */
  height: 60px
}

.HeaderLeft {
  display: flex;
  align-items: center;
  height: 100%;
}

.logo {
  height: 50px;
  cursor: pointer;
  display: block;
  margin: 0;
}

.HeaderCenter {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end; /* Align header items to the right */
  align-items: center;
}

.headerItems {
  display: flex;
  align-items: center;
  gap: 25px; /* Space between header items */
}

.headerItem {
  font-family: $subtitleFontFamily; /* Using defined font family */
  font-size: 16px;
  color: $textColor; /* Using primary text color */
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.headerItemActive {
  text-decoration: underline;
}

@media screen and (min-width: 800px) {  
  .HeaderCenter {
    margin-right: 50px;
    justify-content: flex-end;
    align-items: center;
  }

  .HeaderLeft {
    margin-left: 25px;
  }
  
  .headerItems {
    margin: 0; /* Remove margin to properly center header items */
  }
}